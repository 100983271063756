import { SanityImage } from "../../../lib/sanity/sanity.types";

export interface Slide {
  title: string;
  description: string;
  cta?: {
    label: string;
    href: string;
  };
  image?: SanityImage;
  alt?: string;
  widget: WidgetType;
  customWidget?: Widget;
}

export type Widget = {
  alt: string;
  position: WidgetPosition;
} & ({ sanityImage: SanityImage } | { localImage: StaticImageData });

export type WidgetPosition =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right";

export enum WidgetType {
  CarePlan = "care-plan",
  GriefPlan = "grief",
  CareManager = "care-manager",
  Probate = "probate",
  ClosingAccounts = "closing-accounts",
  Custom = "custom",
}

export interface WidgetComponentProps {
  imageSrc: string;
  imageWidth?: number;
  imageHeight?: number;
  alt?: string;
  from: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    opacity?: number;
  };
  to: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    opacity?: number;
  };
  exit: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    opacity?: number;
  };
}

import clsx from "clsx";
import React from "react";
interface Props {
  index: number;
  length: number;
}
export function Dots({
  index,
  length
}: Props) {
  return <ul className={clsx("flex-col justify-center items-center gap-3 sticky top-[calc(50vh-270px)] h-[540px] w-auto self-start hidden lg:flex")} data-sentry-component="Dots" data-sentry-source-file="Dots.tsx">
      {Array.from({
      length
    }).map((_, i) => <li key={i}>
          <div className={clsx("h-2 w-2 rounded-full transition-all", {
        "bg-plum-80 scale-150": i === index,
        "bg-plum-16": i !== index
      })}></div>
        </li>)}
    </ul>;
}
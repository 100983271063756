import { toFieldPath } from "@stackbit/annotations";
import React, { useState } from "react";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { TitleArea } from "../components/TitleArea";
import { Dots } from "./Dots";
import { ProductSlideMobile } from "./ProductSlideMobile";
import { ProductSlideDesktop } from "./ProductSlideDesktop";
import { Slide } from "./types";
interface ProductScrollProps extends SharedSanitySectionProps {
  slides?: Slide[];
  preheader?: string;
}
const SectionProductScroll: React.FC<ProductScrollProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  slides = [],
  title,
  preheader
}) => {
  const [index, setIndex] = useState(0);
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} className="max-w-screen overflow-x-hidden lg:overflow-x-visible" {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionProductScroll" data-sentry-source-file="SectionProductScroll.tsx">
      <Container className="relative" data-sentry-element="Container" data-sentry-source-file="SectionProductScroll.tsx">
        <TitleArea preheader={preheader} title={title} isEyebrow titleAlignment="center" className="mb-16 lg:mb-24" data-sentry-element="TitleArea" data-sentry-source-file="SectionProductScroll.tsx" />
        <div className="flex flex-col lg:flex-row items-stretch relative gap-x-10">
          <div className="flex-1" {...toFieldPath(".slides")}>
            {slides.map((slide, i) => <ProductSlideMobile {...toFieldPath(`.${i}`)} index={i} slide={slide} key={i} onChange={inView => {
            if (inView) {
              setIndex(i);
            }
          }} />)}
          </div>
          <div className="flex-1 relative hidden lg:block">
            <div className="w-[600px] h-[540px] flex sticky top-[calc(50vh-270px)]">
              {slides.map((slide, i) => <ProductSlideDesktop {...toFieldPath(`.${i}`)} key={i} index={i} currentIndex={index} slide={slide} />)}
            </div>
          </div>
          <Dots length={slides.length} index={index} data-sentry-element="Dots" data-sentry-source-file="SectionProductScroll.tsx"></Dots>
        </div>
      </Container>
    </Section>;
};
export default SectionProductScroll;
import { toFieldPath } from "@stackbit/annotations";
import { motion } from "framer-motion";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import { useInView } from "react-intersection-observer";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
import { TextWithArrow } from "../components/TextWithArrow";
import { getWidgetForSlide } from "./lib/getWidget";
import { Slide, WidgetComponentProps } from "./types";
interface Props {
  index: number;
  slide: Slide;
  onChange: (inView: boolean) => void;
}
export function ProductSlideMobile({
  onChange,
  slide,
  ...props
}: Props) {
  const {
    ref,
    inView
  } = useInView({
    threshold: 1
  });
  React.useEffect(() => {
    onChange(inView);
  }, [inView]);
  const widget = getWidgetForSlide(slide);
  return <div {...props} className="w-full max-w-[600px] mx-auto lg:h-[540px] flex flex-col justify-center mb-16 last:mb-0 lg:mb-0" ref={ref} data-sentry-component="ProductSlideMobile" data-sentry-source-file="ProductSlideMobile.tsx">
      <div className="mb-4 max-w-[600px] lg:h-[540px] relative flex items-stretch lg:hidden">
        <Image width={600} height={540} className="rounded-2xl" src={imageUrlFor(slide.image)} objectFit="cover" objectPosition="top center" alt={slide.alt} quality={100} {...toFieldPath(".image")} data-sentry-element="Image" data-sentry-source-file="ProductSlideMobile.tsx" />
        <motion.div {...toFieldPath(".widget")} className="absolute" initial={widget.from} whileInView={mobileWidget(widget).to} transition={{
        duration: 0.5
      }} data-sentry-element="unknown" data-sentry-source-file="ProductSlideMobile.tsx">
          <Image src={widget.imageSrc} width={widget.imageWidth / 3} height={widget.imageHeight / 3} style={{
          ...mobileWidget(widget).to
        }} quality={100} priority alt={widget.alt} data-sentry-element="Image" data-sentry-source-file="ProductSlideMobile.tsx" />
        </motion.div>
      </div>
      <div className="text-h2 lg:text-h1 mt-6 lg:mt-0" {...toFieldPath(".title")}>
        {slide.title}
      </div>
      <p className="text-body-large mt-2 lg:mr-14" {...toFieldPath(".description")}>
        {slide.description}
      </p>
      {slide.cta && <Link className="block hover:underline mt-6" href={slide.cta.href}>
          <TextWithArrow fieldPath=".cta.label" text={slide.cta.label} />
        </Link>}
    </div>;
}
const mobileWidget = (widget: WidgetComponentProps): WidgetComponentProps => {
  if (widget.to.left) {
    return {
      ...widget,
      to: {
        ...widget.to,
        left: widget.to.left + 14
      }
    };
  }
  if (widget.to.right) {
    return {
      ...widget,
      to: {
        ...widget.to,
        right: widget.to.right + 14
      }
    };
  }
  return widget;
};
import { imageUrlFor, sanityImageSize } from "../../../../lib/sanity/sanity.image";
import { WidgetType, Widget, Slide, WidgetComponentProps, WidgetPosition } from "../types";
import { WIDGETS } from "./WIDGETS";
export const getWidgetForSlide = (slide: Slide): WidgetComponentProps => expandWidgetProps(slide.customWidget || widgetFromType(slide.widget));
const widgetFromType = (slug: WidgetType): Widget => WIDGETS[slug] || WIDGETS[WidgetType.CarePlan];
const expandWidgetProps = (widget: Widget): WidgetComponentProps => {
  const position = positions[widget.position];
  return {
    ...imageDetails(widget),
    alt: widget.alt,
    from: position.from,
    to: position.to,
    exit: {
      opacity: 0
    }
  };
};
const imageDetails = (widget: Widget) => {
  if ("sanityImage" in widget) {
    const url = imageUrlFor(widget.sanityImage);
    const size = sanityImageSize(url);
    return {
      imageWidth: size.width,
      imageHeight: size.height,
      imageSrc: url
    };
  }
  return {
    imageWidth: widget.localImage.width,
    imageHeight: widget.localImage.height,
    imageSrc: widget.localImage.src
  };
};
const positions: Record<WidgetPosition, Pick<WidgetComponentProps, "from" | "to">> = {
  "top-left": {
    from: {
      left: -40,
      top: -24,
      opacity: 0
    },
    to: {
      left: -24,
      top: -24,
      opacity: 1
    }
  },
  "top-right": {
    from: {
      right: -40,
      top: -24,
      opacity: 0
    },
    to: {
      right: -24,
      top: -24,
      opacity: 1
    }
  },
  "bottom-left": {
    from: {
      left: -40,
      bottom: -24,
      opacity: 0
    },
    to: {
      left: -24,
      bottom: -24,
      opacity: 1
    }
  },
  "bottom-right": {
    from: {
      right: -40,
      bottom: -24,
      opacity: 0
    },
    to: {
      right: -24,
      bottom: -24,
      opacity: 1
    }
  }
};
import Image from "next/image";
import { toFieldPath } from "@stackbit/annotations";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import React from "react";
import { imageUrlFor } from "../../../lib/sanity/sanity.image";
import { getWidgetForSlide } from "./lib/getWidget";
import { Slide } from "./types";
interface Props {
  index: number;
  currentIndex: number;
  slide: Slide;
}
export const ProductSlideDesktop: React.FC<Props> = ({
  index,
  currentIndex,
  slide
}) => {
  const widget = getWidgetForSlide(slide);
  return <div className={clsx("absolute flex justify-center items-center")} data-sentry-component="ProductSlideDesktop" data-sentry-source-file="ProductSlideDesktop.tsx">
      <motion.div initial={{
      opacity: 0
    }} transition={{
      duration: 0.7
    }} animate={{
      opacity: currentIndex === index ? 1 : 0
    }} className={clsx({
      "pointer-events-none": currentIndex !== index
    })} aria-hidden={currentIndex !== index} data-sentry-element="unknown" data-sentry-source-file="ProductSlideDesktop.tsx">
        <Image width={600} height={540} layout="fixed" objectFit="cover" objectPosition="top center" className="rounded-2xl" src={imageUrlFor(slide.image)} alt={widget.alt} quality={100} {...toFieldPath(`.slides.${index}.image`)} data-sentry-element="Image" data-sentry-source-file="ProductSlideDesktop.tsx" />
      </motion.div>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="ProductSlideDesktop.tsx">
        {currentIndex === index && <motion.div {...toFieldPath(`.slides.${index}.widget`)} key={`widget-${index}`} initial={widget.from} animate={widget.to} exit={{
        ...widget.exit,
        transition: {
          delay: 0
        }
      }} transition={{
        delay: 0.1,
        duration: 0.5,
        ease: "easeInOut"
      }} className="absolute bg-transparent">
            <Image src={widget.imageSrc} width={widget.imageWidth / 2} height={widget.imageHeight / 2} alt={widget.alt} quality={100} priority />
          </motion.div>}
      </AnimatePresence>
    </div>;
};
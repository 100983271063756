import widget1 from "../../../../public/images/productScroll/widget-1.png";
import widget2 from "../../../../public/images/productScroll/widget-2.png";
import widget3 from "../../../../public/images/productScroll/widget-3.png";
import widget4 from "../../../../public/images/productScroll/widget-4.png";
import widget5 from "../../../../public/images/productScroll/widget-5.png";
import { Widget, WidgetType } from "../types";
export const WIDGETS: Record<WidgetType, Widget> = {
  [WidgetType.CarePlan]: {
    localImage: widget1,
    alt: "Your Care Plan Widget",
    position: "bottom-right"
  },
  [WidgetType.ClosingAccounts]: {
    localImage: widget2,
    alt: "Closing Accounts Widget including closed Facebook and Comcast (Xfinity) account, and a pending closing of PayPal account",
    position: "top-right"
  },
  [WidgetType.GriefPlan]: {
    localImage: widget3,
    alt: "Grief Support Widget - Feel safe breathing exercise",
    position: "bottom-left"
  },
  [WidgetType.CareManager]: {
    localImage: widget4,
    alt: "Care Manager Widget - Your next call with grace is at 24pm",
    position: "bottom-right"
  },
  [WidgetType.Probate]: {
    localImage: widget5,
    alt: "Probate Widget - showcasing Florida's probate threshold",
    position: "bottom-right"
  },
  [WidgetType.Custom]: {
    localImage: widget1,
    alt: "",
    position: "bottom-right"
  }
};